// linear-gradient(90deg, rgba(182,0,18,1) 0%, rgba(69,195,237,1) 100%);
export const MAIN_GRAD_START = 'rgba(255, 102, 255, 1)';
// export const MAIN_GRAD_START = 'rgba(222, 0, 22,1)';
export const MAIN_GRAD_END = 'rgba(36, 247, 255, 1)';
// export const MAIN_GRAD_END = 'rgba(102, 217, 255,1)';
export const MAIN_GRAD = `linear-gradient(
  90deg,
  ${MAIN_GRAD_START} 0%,
  ${MAIN_GRAD_END} 100%
)`;

export const MAIN_GRAD_TEXT = {
  display: 'inline-block',
  letterSpacing: '1.5px',
  background: MAIN_GRAD,
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundClip: 'text',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
};

export const RULES_CARD_ID_1 = 'rulesCard1';
export const RULES_CARD_ID_2 = 'rulesCard2';
export const TALENT_SELECT_CARD_ID = 'selectCard';
