import { useEffect, useState } from 'react';
import CustomDialog from '../misc/CustomDialog';
import CustomButton from '../misc/CustomButton';
import { COOKIE_POLICY_URL } from '../../data';
import { MAIN_GRAD_START } from '../../guiConstants';

const HAS_ACCCEPTED_COOKIES = 'hasAcceptedCookies';

function CookieAlert() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem(HAS_ACCCEPTED_COOKIES);
    if (!hasAcceptedCookies) setOpen(true);
  }, []);

  const handleAccept = () => {
    localStorage.setItem(HAS_ACCCEPTED_COOKIES, 'true');
    setOpen(false);
  };

  return (
    <CustomDialog open={open} onClose={handleAccept}>
      <div
        style={{
          color: '#FFF',
          padding: 24,
          fontSize: '1rem',
          lineHeight: 1.5,
        }}
      >
        Noi e alcuni partner selezionati utilizziamo cookie o tecnologie simili
        come specificato nella{' '}
        <a
          style={{ color: MAIN_GRAD_START }}
          href={COOKIE_POLICY_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          cookie policy
        </a>
        . Puoi acconsentire all’utilizzo di tali tecnologie e continuare sulla
        pagina cliccando su accetta o cliccando al di fuori del popup.
      </div>
      <CustomButton
        text="ACCETTA"
        onClick={handleAccept}
        style={{ margin: 20 }}
      />
    </CustomDialog>
  );
}

export default CookieAlert;
