import { createContext, useState, useEffect, PropsWithChildren } from 'react';
import { db } from '../fbConfig';
import { IPublicInfo } from '../interfaces';
import { MNG_INFO_COLL_ID, PUBLIC_INFO_DOC_ID } from '../constants';
import { doc, onSnapshot } from 'firebase/firestore';

type TPublicInfoCtx = undefined | IPublicInfo;
const PublicInfoCtxInitValue = undefined;

export const PublicInfoCtx = createContext<TPublicInfoCtx>(
  PublicInfoCtxInitValue
);

function PublicInfoProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [publicInfo, setPublicInfo] = useState<TPublicInfoCtx>(
    PublicInfoCtxInitValue
  );

  useEffect(() => {
    const clearOnSnapshotPrompt = onSnapshot(
      doc(db, MNG_INFO_COLL_ID, PUBLIC_INFO_DOC_ID),
      snap => {
        // retrigger onSchedule check when the state of an onPrompt
        // to see if there any available onShcedule session
        setPublicInfo(snap.data() as IPublicInfo);
      },
      err => {
        console.error('ERROR WHEN LISTENING TO ON PROMPT SESSIONS', err);
        setPublicInfo(undefined);
      }
    );
    return () => {
      clearOnSnapshotPrompt();
    };
  }, []);

  return (
    <PublicInfoCtx.Provider value={publicInfo}>
      {children}
    </PublicInfoCtx.Provider>
  );
}

export default PublicInfoProvider;
