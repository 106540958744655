import { MAIN_GRAD } from '../../guiConstants';

import { ReactNode, CSSProperties } from 'react';
interface IGradBordeProps {
  children: ReactNode;
  color?: string;
  style?: CSSProperties;
}

function GradBorder(props: IGradBordeProps) {
  const { children, color, style } = props;
  return (
    <div
      style={{
        position: 'relative',
        background: color ?? MAIN_GRAD,
        padding: 3,
        borderRadius: 10,
        ...style,
      }}
    >
      <div
        style={{
          borderRadius: 10,
          background: '#000',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default GradBorder;
