import React, { Dispatch, SetStateAction } from "react";
import TextField from "@material-ui/core/TextField";
import GradBorder from "./CustomBorder";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import useDebounce from "../../hooks/useDebounce";
interface ICustomTextInputProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  label: string;
  disabled?: boolean;
  style?: CSSProperties;
  type: "text" | "password";
  required?: boolean;
  error?: (val: string) => boolean;
  errorMsg?: string;
}

function CustomTextField(props: ICustomTextInputProps) {
  const {
    value,
    setValue,
    label,
    disabled,
    style,
    type,
    required,
    error,
    errorMsg,
  } = props;

  const [valForError, isDebouncing] = useDebounce(value, 1500);
  const isError = value !== "" && !isDebouncing && error?.(valForError);

  return (
    <GradBorder
      style={{
        margin: 20,
        opacity: disabled ? 0.65 : 1,
        ...style,
      }}
    >
      <TextField
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{
          color: "white",
          margin: 8,
          opacity: disabled ? 0.65 : 1,
          width: "calc(100% - 15px)",
        }}
        type={type}
        inputProps={{ style: { color: "white" } }}
        InputLabelProps={{ style: { color: "white" } }}
        variant="outlined"
        label={label}
        disabled={disabled}
        required={required}
        error={isError}
        helperText={isError && errorMsg}
      />
    </GradBorder>
  );
}

export default CustomTextField;
