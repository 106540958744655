import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { SESSIONS_COLL_ID } from "../constants";
import { db } from "../fbConfig";
import { ISession } from "../interfaces";

export default function useSessionActive(targetProp: keyof ISession) {
  const [sessionId, setSessionId] = useState<undefined | null | string>(
    undefined
  );

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      query(collection(db, SESSIONS_COLL_ID), where(targetProp, "==", true)),
      (snaps) => {
        if (snaps.size > 1) {
          console.error(`THERE ARE TOO MANY OPEN SESSION FOR ${targetProp}`);
          console.error("CHECK ADMIN CONSOLE");
          console.info("CHECK ADMIN CONSOLE");
          setSessionId(undefined);
        } else if (snaps.size === 0) {
          setSessionId(null);
        } else {
          setSessionId(snaps.docs[0].id);
        }
      },
      (err) => {
        console.error(
          `ERROR WHEN WATCHING OPEN SESSIONS FOR ${targetProp}`,
          err
        );
      }
    );

    return () => {
      clearOnSnapshot();
    };
  }, [targetProp]);

  return sessionId;
}
