import { Box, ListSubheader, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { Fragment, SetStateAction } from "react";
import { Dispatch } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GradBorder from "./CustomBorder";

export type MenuItemValue = string | number;

type CustomSelectOption<TValue extends MenuItemValue> = {
  label: string;
  value: TValue;
};

interface ICustomSelectBaseProps<TValue extends MenuItemValue> {
  value: TValue;
  setValue: Dispatch<SetStateAction<TValue>>;
  style?: CSSProperties;
  gradBorderStyle?: CSSProperties;
  label: string;
}
interface ICustomSelectNoGroupedProps<TValue extends MenuItemValue>
  extends ICustomSelectBaseProps<TValue> {
  grouped?: false;
  options: CustomSelectOption<TValue>[];
  groupedOptions?: never;
}

interface ICustomSelectGroupedProps<TValue extends MenuItemValue>
  extends ICustomSelectBaseProps<TValue> {
  grouped: true;
  options?: never;
  groupedOptions: {
    name: string;
    options: CustomSelectOption<TValue>[];
  }[];
}

type ICustomSelectProps<TValue extends MenuItemValue> =
  | ICustomSelectGroupedProps<TValue>
  | ICustomSelectNoGroupedProps<TValue>;

function CustomSelect<TValue extends MenuItemValue>(
  props: ICustomSelectProps<TValue>
) {
  const {
    value,
    groupedOptions,
    options,
    grouped,
    setValue,
    style,
    label,
    gradBorderStyle,
  } = props;

  return (
    <>
      <GradBorder
        style={{
          width: "100%",
          ...gradBorderStyle,
        }}
      >
        <Box id="buya" style={{ position: "relative", width: "100%" }}>
          {value === "" && (
            <Typography
              style={{
                position: "absolute",
                left: 20,
                bottom: -42,
                zIndex: 200,
              }}
            >
              {label}
            </Typography>
          )}
          <ArrowDropDownIcon
            fontSize="large"
            style={{
              position: "absolute",
              right: 10,
              bottom: -42,
              zIndex: 200,
            }}
          />
        </Box>
        <Select
          value={value}
          onChange={(ev) => {
            setValue(ev.target.value as TValue);
          }}
          variant="outlined"
          style={{
            margin: "auto",
            zIndex: 1,
            color: "#FFF",
            width: "100%",
            ...style,
          }}
          SelectDisplayProps={{
            style: {
              color: "#FFFFFF",
              border: "2px solid #FFFFFF",
              width: "100%",
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                color: "#FFFFFF",
                backgroundColor: "#000000",
                border: "2px solid #888888",
                margin: 10,
              },
            },
          }}
        >
          {grouped
            ? groupedOptions?.map((group) => [
                <ListSubheader
                  style={{ fontSize: "1rem" }}
                  color="primary"
                  onClickCapture={(e) => e.stopPropagation()}
                >
                  <b>{group.name}</b>
                </ListSubheader>,
                group.options.map((opt, optIdx) => (
                  <MenuItem
                    key={optIdx}
                    value={opt.value}
                    style={{ marginLeft: 12 }}
                  >
                    {opt.label}
                  </MenuItem>
                )),
              ])
            : options?.map((opt, optIdx) => (
                <MenuItem key={optIdx} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
        </Select>
      </GradBorder>
    </>
  );
}

export default CustomSelect;
