import { ReactNode, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import GradBorder from './CustomBorder';
import CustomDialog from './CustomDialog';

const useStyles = makeStyles(theme => ({
  confirmDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 32,
    fontSize: '1rem',
    color: 'white',
    textAlign: 'center',
  },
}));

interface IConfirmDialogBaseProps {
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
  children: ReactNode;
}

interface IConfirmDialognoFutherProps extends IConfirmDialogBaseProps {
  shouldAskFurtherConfirm?: never;
  furtherConfirmContent?: never;
}

interface IConfirmDialogFutherProps extends IConfirmDialogBaseProps {
  shouldAskFurtherConfirm: boolean;
  furtherConfirmContent: ReactNode;
}

type IConfirmDialogProps =
  | IConfirmDialognoFutherProps
  | IConfirmDialogFutherProps;

function ConfirmDialog(props: IConfirmDialogProps) {
  const classes = useStyles();
  const {
    open,
    onConfirm,
    children,
    onCancel,
    onClose,
    shouldAskFurtherConfirm,
    furtherConfirmContent,
  } = props;

  const [furtherConfirmOpen, setFurtherConfirmOpen] = useState(false);
  return (
    <>
      <CustomDialog open={open} onClose={onClose}>
        <div className={classes.confirmDialog}>
          <div style={{ padding: 5 }}>{children}</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <GradBorder style={{ margin: ' 0 20px 0 0' }}>
              <IconButton
                style={{
                  color: 'white',
                }}
                onClick={() => {
                  onCancel?.();
                  onClose();
                }}
              >
                <ClearIcon fontSize="large" />
              </IconButton>
            </GradBorder>
            <GradBorder style={{ margin: '0 0 0 20px' }}>
              <IconButton
                style={{
                  color: 'white',
                }}
                onClick={() => {
                  if (shouldAskFurtherConfirm) setFurtherConfirmOpen(true);
                  else onConfirm();
                }}
              >
                <CheckIcon fontSize="large" />
              </IconButton>
            </GradBorder>
          </div>
        </div>
      </CustomDialog>
      {furtherConfirmContent && (
        <ConfirmDialog
          open={furtherConfirmOpen}
          onClose={() => {
            setFurtherConfirmOpen(false);
          }}
          onConfirm={() => {
            onConfirm();
            setFurtherConfirmOpen(false);
          }}
          onCancel={onClose}
        >
          {furtherConfirmContent}
        </ConfirmDialog>
      )}
    </>
  );
}

export default ConfirmDialog;
