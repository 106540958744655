import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./customFonts.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import CookieAlert from "./components/utilities/CookieAlert";
import PublicInfoProvider from "./contexts/PubliclnfoCtx";
import GiftTicketsReserver from "./components/GiftTicketsReserver";

const theme = createTheme({
  typography: {
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  palette: {
    primary: { main: "#FFF" },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
          height: "100%",
          width: "100%",
          scrollBehavior: "smooth",
        },
        body: {
          height: "100%",
          width: "100%",
          backgroundColor: "#000",
        },
        "*": {
          fontFamily: "Helvetica",
          margin: 0,
          padding: 0,
        },
        "h1,h2": {
          fontFamily: "Gen Vision Font",
          letterSpacing: 2,
        },
        "h1,h2,h3": {
          color: "#FFF",
        },
        a: {
          color: "#FFF",
          textDecoration: "none",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0,0,0, 0.8)",
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div>
          <CookieAlert />
          <PublicInfoProvider>
            <Switch>
              <Route path="/">
                <GiftTicketsReserver />
              </Route>
              <Route path="**">
                <Redirect to="/" />
              </Route>
            </Switch>
          </PublicInfoProvider>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
