import { EScheduleLimits, ESessionOpenTarget } from "./interfaces";

export const ADD_INFO_COLLECTION_ID = "addInfo";

export const NON_AUDIENCE_USER_DATA_DOC_ID = "nonAudUserCred";

export const JURY_VOTES_DOC_ID = "juryVotes";

export const RAPPR_VOTES_DOC_ID = "rapprVotes";

export const LIVE_RANK_DOC_ID = "liveRank";

export const LIVE_RANK_CRED_DOC_ID = "liveRankCred";

export const AUD_RANK_DOC_ID = "audRank";

export const MNG_INFO_COLL_ID = "mngInfo";

export const CONSOLE_USERS_DOC_ID = "consoleUsers";

export const JUDGES_LIST_DOC_ID = "judgesList";

export const PUBLIC_INFO_DOC_ID = "publicInfo";

export const GIFT_TICKETS_COLL_ID = "gift-tickets";

export const TICKETS_COLL_ID = "tickets";

export const SESSIONS_COLL_ID = "sessions";

export const USERS_COLL_ID = "users";

export const TALENTS_COLL_ID = "talents";

export const GROUPS_COLL_ID = "groups";

export const FANTA_COLL_ID = "fanta";

export const FANTA_SESSION_ID = "2022";

export const FANTA_USERS_COLL_ID = "users";

export const FANTA_PROFESSORS_COLL_ID = "professors";

export const FANTA_SCHOOLS_COLL_ID = "schools";

export const FANTA_EVENTS_COLL_ID = "events";

export const FANTA_RANK_COLL_ID = "rank";

export const FANTA_TOP_RANK_COLL_ID = "rank";

export const FANTA_REFERRALS_COLL_ID = "referrals";

export const FANTA_TICKET_CODES_COLL_ID = "ticketCodes";

export const KAPLAN_URL =
  "https://www.kaplaninternational.com/study/it/brochure-download/?utm_source=banner&utm_medium=affiliate_GENVISION&utm_campaign=GENVISION2022&utm_content=AFF-GENVISION-BD";

export const SUCCESS_COLOR = "#5ee67c";
export const ERROR_COLOR = "#f75e5e";

// functions
export const FUNC_REGION = "europe-west3";
export const NUM_SHARDS = 10;

export const DATE_PICKER_LABEL: { [key in EScheduleLimits]: string } = {
  end: "Fine",
  start: "Inizio",
};
export const OPEN_TARGET: { [key in ESessionOpenTarget]: string } = {
  openAud: "Pubblico",
  openNonAud: "Giudici e rappresentanti",
};

export const SOCIAL_JUDGES = [
  "fedeasso",
  "jrstit",
  "ssamudibe",
  "alessiaadagostino",
  "callmebucci",
  "leoucifero",
];
